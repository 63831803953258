<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Nuevo cobro</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <company-field
                  :textLabel="'Sucursal Origen'"
                  v-model="companyId"
                  @value="(val) => (companyId = val)"
                ></company-field>
              </v-col>
              <v-col cols="12" md="6">
                <internal-account-field
                  v-model="accountInternal"
                  :isRequired="true"
                  :sourceCompany="companyId"
                ></internal-account-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Descripción"
                  v-model="detail.description"
                  :rules="onlyRequired"
                  ref="rdesc"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Cantidad"
                  v-model="detail.quantity"
                  type="number"
                  step="any"
                  :rules="decimalRequired"
                  ref="rquant"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Precio"
                  v-model="detail.price"
                  type="number"
                  step="any"
                  :rules="decimalRequired"
                  ref="rpric"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Subtotal"
                  v-model="detail.subtotal"
                  type="number"
                  step="any"
                  :rules="decimalRequired"
                  ref="rsubt"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn depressed small color="primary" class="mt-5" @click="addDetail()">
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <br />
          <p class="text-left text--disabled">Detalle del cobro</p>
          <v-data-table
            :headers="headers"
            :items="details"
            class="elevation-1"
            hide-default-footer
            disable-sort
            disable-pagination
          >
            <template v-slot:item.quantity="{ item }">
              {{ item.quantity | currency }}
            </template>
            <template v-slot:item.price="{ item }">
              Q{{ item.price | currency }}
            </template>
            <template v-slot:item.subtotal="{ item }">
              Q {{ item.subtotal | currency }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon dark color="red lighten-1" @click="removeDetail(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="12" md="7"> <span>Total:</span></v-col>
            <v-col cols="12" md="5">
              <b>Q. {{ subtotal | currency }}</b></v-col
            >
          </v-row>
          <br />
          <v-row class="d-flex flex-row-reverse">
            <v-btn color="success" :disabled="!details.length > 0" @click="save()">
              Guardar</v-btn
            >
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <detail-billing
      v-if="dialogBilling"
      :billingId="idBilling"
      :dialog="dialogBilling"
      @close="(dialogBilling = false), (idPayment = null), $emit('close')"
    ></detail-billing>
  </v-row>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules.js";
export default {
  name: "BillingForm",
  components: {
    InternalAccountField: () => import("@/components/fields/AccountInternalField.vue"),
    CompanyField: () => import("@/components/fields/CompanyField.vue"),
    DetailBilling: () => import("@/components/dealings/DetailBilling.vue"),
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  data() {
    return {
      dialogBilling: false,
      idBilling: null,
      companyId: 0,
      valid: false,
      accountInternal: null,
      body: {
        internal_account_id: undefined,
      },
      details: [],
      detail: {
        description: "",
        quantity: 0,
        price: 0,
        subtotal: 0,
      },
      headers: [
        { text: "Descripción", value: "description" },
        { text: "Cantidad", value: "quantity" },
        { text: "Precio", value: "price" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Acciones", value: "actions" },
      ],
      //rules
      onlyRequired: [rules.required],
      decimalRequired: [rules.required, rules.minValue(0.01)],
      subtotal: 0,
    };
  },
  methods: {
    addDetail() {
      this.validate();
      if (this.valid) {
        this.details.push(this.detail);
        this.detail = {
          description: "",
          quantity: 0,
          price: 0,
          subtotal: 0,
        };
        this.$refs.rdesc.reset();
        this.$refs.rquant.reset();
        this.$refs.rpric.reset();
        this.$refs.rsubt.reset();
      }
    },
    removeDetail(index) {
      this.details.splice(this.details.indexOf(index), 1);
    },
    save() {
      this.loading = true;
      if (this.accountInternal == undefined || this.accountInternal == null) {
        this.$toasted.global.info({ message: "Debe seleccionar una cuenta interna" });
        return;
      } else {
        this.body.internal_account_id = this.accountInternal.id;
        if (this.details.length == 0) {
          this.$toasted.global.info({ message: "Debe agregar al menos un detalle" });
          return;
        } else {
          this.body.details = this.details;
          requests
            .post("/api/bussiness-connector/internal-billing/", this.body)
            .then((res) => {
              if (res.status == 201) {
                this.$toasted.global.info({ message: "Cobro guardado" });
                // this.close();
                this.dialogBilling = true;
                this.idBilling = res.data.id;
                this.loading = false;
              }
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    close() {
      this.$emit("close");
      this.$emit("update");
    },
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
    },
    details: {
      handler: function (val) {
        this.subtotal = 0;
        if (val.length > 0) {
          val.forEach((element) => {
            this.subtotal += Number(element.subtotal);
          });
        }
        this.subtotal = this.subtotal.toFixed(2);
      },
      deep: true,
    },
    detail: {
      handler: function (val) {
        if (Number(val.quantity) > 0 && Number(val.price) > 0)
          this.detail.subtotal = Number(val.quantity * val.price).toFixed(2);
      },
      deep: true,
    },
  },
};
</script>
